.bg-img{
    position: relative;
}
.bg-img:after {
    content:'';
    background: url('https://inf-shared-assets-s3.s3.amazonaws.com/platform-login-screen/frosted-glass.png') no-repeat center center;
    position: absolute;
    top:0px;
    left: 0px;
    width:100%;
    height:100%;
    z-index:-1;
    opacity: 0.5;
    border-radius: 0.5rem;
}