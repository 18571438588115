
/* coverflow from scratch */

.coverflow-wrapper {
  perspective: 1200px;
}

.coverflow {
  transform-style: preserve-3d;
  transition: transform 1s;
  
}

.slide-image {
  transform-style: preserve-3d;
  animation: load 2s;
  transform-origin: bottom;
}

@keyframes load {
  0% { scale: 0; }
  100% {scale: 1;}
}
