@tailwind base;
@tailwind components;
@tailwind utilities;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

@layer base {
  @font-face {
    font-family: PencilPete;
    font-weight: 400;
    src: url(/src/fonts/Pencil-Pete.ttf);
  }
  @font-face {
    font-family: Oswald;
    font-weight: 400;
    src: url(/src/fonts/Oswald-Regular.ttf);
  }
}

.loading-indicator:before {
  content: "";
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: url("./assets/images/loading.gif");
  position: fixed;
  width: 100%;
  top: 40%;
  left: 0;
  z-index: 1001;
  text-align: center;
}
